import React from 'react'

const Hero = ({ h1, page }) => {
    return (
        <div className={`hero ${page}`}>
            <div className="decor"></div>
            <div className="prev">
                <h1>{h1}</h1>
            </div>
            <div className="post"></div>
            <div className="container"></div>
        </div>
    )
}

export default Hero
