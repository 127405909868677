import React from 'react'
import checkCircle from '../images/icons/check-circle-light.svg'
import { injectIntl } from 'gatsby-plugin-intl'

const MainBlock = ({ page, intl }) => {
    return (
        <div className={`main-block ${page}`}>
            <div className="container">
                {page === 'index' && <div className="content-col">
                    <h2>{intl.formatMessage({id: "home.main.title"})}</h2>
                    <p>{intl.formatMessage({id: "home.main.desc"})}</p>
                </div>}

                {page === 'individuos' && <div className="content-col">
                    <h2>{intl.formatMessage({id: "individuos.main.title"})}</h2>
                    <ul>
                       <li><div className="icon"><img src={checkCircle} className="m-r-15" width={20} alt=""/></div>
                        {intl.formatMessage({id: "individuos.main.list.l1"})}</li>
                        <li><div className="icon"><img src={checkCircle} className="m-r-15" width={20} alt=""/></div>
                        {intl.formatMessage({id: "individuos.main.list.l2"})}</li>
                        <li><div className="icon"><img src={checkCircle} className="m-r-15" width={20} alt=""/></div>
                        {intl.formatMessage({id: "individuos.main.list.l3"})}</li>
                    </ul>
                    <a rel="noreferrer" href="/users/signups/new" className="btn-primary">{intl.formatMessage({id: "individuos.main.cta"})}</a>
                </div>}

                {page === 'empresas' && <div className="content-col">
                    <h2>{intl.formatMessage({id: "empresas.main.title"})}</h2>
                    <ul>
                       <li><div className="icon"><img src={checkCircle} className="m-r-15" width={20} alt=""/></div>
                       {intl.formatMessage({id: "empresas.main.list.l1"})}</li>
                        <li><div className="icon"><img src={checkCircle} className="m-r-15" width={20} alt=""/></div>
                        {intl.formatMessage({id: "empresas.main.list.l2"})}</li>
                        <li><div className="icon"><img src={checkCircle} className="m-r-15" width={20} alt=""/></div>
                        {intl.formatMessage({id: "empresas.main.list.l3"})}</li>
                        <li><div className="icon"><img src={checkCircle} className="m-r-15" width={20} alt=""/></div>
                        {intl.formatMessage({id: "empresas.main.list.l4"})}</li>
                        <li><div className="icon"><img src={checkCircle} className="m-r-15" width={20} alt=""/></div>
                        {intl.formatMessage({id: "empresas.main.list.l5"})}</li>
                    </ul>
                    <a rel="noreferrer" href="/users/business/new" className="btn-primary">{intl.formatMessage({id: "empresas.main.cta"})}</a>
                </div>}


            </div>
        </div>
    )
}

export default injectIntl(MainBlock)
