import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Hero from '../components/hero';
import MainBlock from '../components/main-block';
import LkpCard from '../components/LkpCard';
import GreenBlockCta from '../components/greenBlockCta';

import creditCard from '../images/icons/credit-card-light.svg'
import exchange from '../images/icons/exchange-light.svg'
import gift from '../images/icons/gift-light.svg'
import fileInvoiceDollar from '../images/icons/file-invoice-dollar-light.svg'
import smileWink from '../images/icons/smile-wink-light.svg'

import lockAlt from '../images/icons/lock-alt-light.svg'
import thumbsUp from '../images/icons/thumbs-up-light.svg'

import { injectIntl } from "gatsby-plugin-intl"

import qr from '../images/qr.jpg'


const IndividuosPage = ({ intl }) => (
	<Layout page={'individuos'}>
		<SEO title="Home" />
		<Hero page={'individuos'} h1={intl.formatMessage({id: "individuos.hero.title"})}/>
		
		<MainBlock page={'individuos'}/>

		<div className="beneficios-individuos">
			<div className="container">
				<div className="beneficios-heading">
					<h2 className="text-center">{intl.formatMessage({id: "individuos.benefits.title"})}</h2>
				</div>
				<div className="beneficios-grid">
					<LkpCard className="beneficios-card">
						<div className="icon-cont"><img src={creditCard} alt=""/></div>
						<div className="content">
							<h4>{intl.formatMessage({id: "individuos.benefits.blocks.b1.title"})}</h4>
							<p>{intl.formatMessage({id: "individuos.benefits.blocks.b1.desc"})}</p>
						</div>
					</LkpCard>
					<LkpCard className="beneficios-card">
						<div className="icon-cont"><img src={exchange} alt=""/></div>
						<div className="content">
							<h4>{intl.formatMessage({id: "individuos.benefits.blocks.b2.title"})}</h4>
							<p>{intl.formatMessage({id: "individuos.benefits.blocks.b2.desc"})}</p>
						</div>
					</LkpCard>
					<LkpCard className="beneficios-card">
						<div className="icon-cont"><img src={gift} alt=""/></div>
						<div className="content">
							<h4>{intl.formatMessage({id: "individuos.benefits.blocks.b3.title"})}</h4>
							<p>{intl.formatMessage({id: "individuos.benefits.blocks.b3.desc"})}</p>
						</div>
					</LkpCard>
					<LkpCard className="beneficios-card">
						<div className="icon-cont"><img src={fileInvoiceDollar} alt=""/></div>
						<div className="content">
							<h4>{intl.formatMessage({id: "individuos.benefits.blocks.b4.title"})}</h4>
							<p>{intl.formatMessage({id: "individuos.benefits.blocks.b4.desc"})}</p>
						</div>
					</LkpCard>
				</div>
			</div>
		</div>
		<div className="container">
			
		</div>
		<div className="individuos-how-it-works-top"></div>
		<div className="individuos-how-it-works">
			<div className="container">
				<h2 className="text-center text-white m-t-100 m-b-50">{intl.formatMessage({id: "individuos.advantages.title"})}</h2>
				<div className="index-features-grid">
					<div className="flex-gap">
						<div className="feature-item">
							<div className="icon"><img src={thumbsUp} height="30" alt=""/></div>
							<h4 className="text-white">{intl.formatMessage({id: "individuos.advantages.i1.title"})}</h4>
							<p className="text-white">{intl.formatMessage({id: "individuos.advantages.i1.desc"})}</p>
						</div>
						<div className="feature-item">
							<div className="icon"><img src={smileWink} height="30" alt=""/></div>
							<h4 className="text-white">{intl.formatMessage({id: "individuos.advantages.i2.title"})}</h4>
							<p className="text-white">{intl.formatMessage({id: "individuos.advantages.i2.desc"})}</p>
						</div>
						<div className="feature-item">
							<div className="icon"><img src={lockAlt} height="30" alt=""/></div>
							<h4 className="text-white">{intl.formatMessage({id: "individuos.advantages.i3.title"})}</h4>
							<p className="text-white">{intl.formatMessage({id: "individuos.advantages.i3.desc"})}</p>
						</div>				
					</div>
				</div>
				<div className="qr-block">
					<img src={qr} alt=""/>
				</div>
			</div>
		</div>
		<div className="individuos-how-it-works-bottom"></div>
		
		<GreenBlockCta 
		page="individuos"
		title={intl.formatMessage({id: "individuos.green-cta.title"})}
		desc={intl.formatMessage({id: "individuos.green-cta.desc"})}
		btn={[intl.formatMessage({id: "individuos.green-cta.cta"}), () => {window.location.replace('/users/business/new')}]}
		/>

	</Layout>
)
	
export default injectIntl(IndividuosPage)
	