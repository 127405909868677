import React from 'react'

const LkpCard = (props) => {
    return (
        <div className={`lkp-card ${props.className}`}>
            {props.children}
        </div>
    )
}

export default LkpCard
